import React from "react"
import { Accordion } from "react-bootstrap"
import "../../components/Settings/ProfilePage/settings.css"
import SettingsLayout from "./settingsLayout"
import LanguageInfo from "./Language/languageInfo"
import Translate from "../translationHelper"

const Language = () => {
  return (
    <SettingsLayout title={`${Translate("Languages & Location")}`} iconClass="globe me-2" isBusinessPage="0">
      <div className="row g-0 pt-5" id="">
        <div className="col">
          <div className="accordion" id="SwiftCloudSettings">
            <div className="accordion-item">
              <h2
                className="accordion-header accordion-button"
                data-bs-toggle="collapse"
                data-bs-target="#languageLocations"
                aria-expanded="true"
                aria-controls="languageLocations"
              >
                <i className="bi bi-globe me-2" /> {`${Translate("Languages & Location")}`}
              </h2>
              <div
                id="languageLocations"
                className="accordion-collapse collapse show generalSettings"
                aria-labelledby="languageLocations"
                data-bs-parent="#SwiftfCloudSettings"
              >
                <div className="accordion-body p-0">
                  <LanguageInfo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SettingsLayout>
  )
}

export default Language
