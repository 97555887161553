import React from "react"
import PrivateRoute from "../../components/privateRoute"
import LanguageDetails from "../../components/Settings/language"
import ReduxWrapper from "../../redux/reduxWrapper"

const Language = props => {
  return <PrivateRoute component={LanguageDetails} location={props.location} />
}
const WrappedPage = props => <ReduxWrapper element={<Language {...props} />} />

export default WrappedPage
