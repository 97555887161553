import React, { useEffect, useState } from "react"
import { save } from "../AboutYourBusiness/helper"
import axios from "axios"

const Currency = ({ data }) => {
  const [currencyList, setCurrencyList] = useState([])

  const [value, setValue] = useState("0"),
    [id, setId] = useState(null)

  const handleSubmit = e => {
    let currencySymbol = currencyList.filter(currency => {
      return currency.code == e.target.value
    })
    if (currencySymbol.length > 0) {
      localStorage.setItem("Currency", JSON.stringify(currencySymbol[0]))
    }
    document.getElementById("currencySymbol").innerHTML = currencySymbol[0].symbol
    save({ id, type: "Currency", value: e.target.value, setValue, setId })
  }

  const getCurrency = () => {
    const uninterceptedAxiosInstance = axios.create()
    uninterceptedAxiosInstance
      .get(`${process.env.GATSBY_API_URL}/currency/list`)
      .then(response => {
        setCurrencyList(response.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (id === null && data) {
      setId(data.id)
      setValue(data.value)
    }
  })

  useEffect(() => {
    getCurrency()
  }, [])

  return (
    <div className="row g-0 block-content align-items-center border-bottom">
      <div className="col-6">
        <h5 className="block-title">Currency</h5>
      </div>
      <div className="col-6 text-end pr-15">
        <select
          name="Currency"
          id="Currency"
          className="form-select"
          key={value}
          value={value}
          onChange={handleSubmit}
        >
          {currencyList.map(currency => {
            return (
              <option value={currency.code} key={`currency_${currency.id}`}>
                {currency.country} {currency.name}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}

export default Currency
