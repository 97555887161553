import React, { useEffect, useState } from "react"
import axios from "axios"
import { save } from "../AboutYourBusiness/helper"

const LanguageDropdown = ({ data }) => {
  const [languages, setLanguages] = useState([])

  const [value, setValue] = useState(""),
    [id, setId] = useState(null)

  const handleSubmit = e => {
    save({ id, type: "Language", value: e.target.value, setValue, setId })
    localStorage.setItem("languageCode", e.target.value)
  }

  useEffect(() => {
    if (id === null && data) {
      setId(data.id)
      setValue(data.value)
    }
  })

  const getLanguages = () => {
    const uninterceptedAxiosInstance = axios.create()
    uninterceptedAxiosInstance
      .get(`${process.env.GATSBY_API_URL}/language/list`)
      .then(response => {
        setLanguages(response.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    getLanguages()
  }, [])

  useEffect(() => {
    if (value) {
      localStorage.setItem("languageCode", value)
    }
  }, [value])

  return (
    <div className="row g-0 block-content align-items-center border-bottom">
      <div className="col-6">
        <h5 className="block-title">Language</h5>
      </div>
      <div className="col-6 text-end pr-15">
        <select
          name="Language"
          id="Language"
          className="form-select"
          key={value}
          value={value}
          onChange={handleSubmit}
        >
          {languages.map(languagesData => {
            return (
              <option value={languagesData.code} key={`languagesData_${languagesData.id}`}>
                {languagesData.language}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}

export default LanguageDropdown
