import React, { useEffect, useState } from "react"
import { save } from "../AboutYourBusiness/helper"

const CommonLanguageDropdown = ({ label, selectName, selectId, options, data }) => {
  const [value, setValue] = useState(""),
    [id, setId] = useState(null)

  const handleSubmit = e => {
    save({ id, type: selectName, value: e.target.value, setValue, setId })
  }

  useEffect(() => {
    if (id === null && data) {
      setId(data.id)
      setValue(data.value)
    }
  })

  return (
    <div className="row g-0 block-content align-items-center border-bottom">
      <div className="col-6">
        <h5 className="block-title">{label}</h5>
      </div>
      <div className="col-6 text-end pr-15">
        <select
          name={selectName}
          id={selectId}
          className="form-select"
          key={value}
          value={value}
          onChange={handleSubmit}
        >
          {options.map(option => {
            return (
              <option value={option.value} key={`option_${option.value}`}>
                {option.label}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}

export default CommonLanguageDropdown
